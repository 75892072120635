import { app } from "./index";
import store from "../../store/index";
import {
  getStorage,
  ref,
  uploadBytes,
  // connectStorageEmulator,
  getBlob,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";

const storage = getStorage(app, "gs://silema.appspot.com");

// if (process.env.NODE_ENV == "development") {
//   connectStorageEmulator(storage, "localhost", 9199); // Only DEV
//   console.log("Emulador cargado");
// }

export function subirArchivo(file) {
  if (file) {
    const uid = store.getters["User/uid"];
    if (uid) {
      const storageRef = ref(storage, `images/cuadrantes/${uid}/${file.name}`);
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          console.log("Subido!", snapshot);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("El usuario debe estar identificado");
    }
  } else {
    console.log("Debes subir un archivo");
  }
}

export function descargarArchivo(filePath) {
  const referencia = ref(storage, filePath);
  getBlob(referencia)
    .then((archivoBlob) => {
      const objectUrl = window.URL.createObjectURL(archivoBlob);
      window.open(objectUrl);
    })
    .catch((err) => {
      console.log(err);
    });
}
// Obtener URL archivo para visualizar en tag Image
export async function obtenerUrlImagen(filePath) {
  const referencia = ref(storage, filePath);
  const url = await getDownloadURL(referencia);
  return url;
}

/**
 *
 * @param {*} file
 * @param {*} carpeta
 * @returns {Promise<string>}
 */
export async function subirArchivoGeneral(file, carpeta) {
  if (file) {
    const extension = file.type.split("/")[1];
    const storageRef = ref(storage, `${carpeta}/${Date.now()}.${extension}`);
    try {
      await uploadBytes(storageRef, file);
      return storageRef.fullPath;
    } catch (error) {
      console.log(error);
      return null;
    }
  } else {
    console.log("debes subir al menos un archivo");
    return null;
  }
}
export function borrarArchivo(filePath) {
  const desertRef = ref(storage, filePath);
  deleteObject(desertRef)
    .then(() => {
      console.log("Archivo eliminado");
    })
    .catch((error) => {
      console.log("Error borrar archivo", error);
    });
}
