import store from "../store/index";
import { computed } from "vue";

/**
 *
 * @param {string} route
 */

const currentUser = computed(() => store.state.User);

export function allowedRoute(route) {
  const userRoles = store.getters["User/roles"];
  const userPermissions = store.getters["User/permissions"];

  // Obtenemos los permisos necesarios para la ruta dada
  const rolesNecesarios = rolesPorRuta[route];
  const permissionsNecesarios = permissionsPorRuta[route];

  // Verificamos si algún rol del usuario está en los roles necesarios para la ruta
  return (
    userRoles.some((rol) => rolesNecesarios.includes(rol)) ||
    userPermissions.some((permission) =>
      permissionsNecesarios.includes(permission)
    )
  );
}

const rolesPorRuta = {
  "/admin": ["admin"],
  "/dashboard": ["admin", "editor"],
  "/profile": ["usuario", "editor"],
};

const permissionsPorRuta = {
  "/admin": ["fdasfdas"],
  "/dashboard": ["agdfhd", "fasohoas"],
  "/profile": ["rewtaew", "ghdlasg"],
};

export function getPermisssions(...validPermisos) {
  if (
    !currentUser.value ||
    !currentUser.value ||
    !currentUser.value.permissions
  ) {
    console.error(
      "Error: No se ha definido correctamente el usuario o sus roles."
    );
    return false;
  }
  const userPermisos = currentUser.value.permissions.map(
    (permiso) => permiso.name
  );
  return validPermisos.some((permisos) => userPermisos.includes(permisos));
}
