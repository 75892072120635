import { createStore } from "vuex";
import User from "./User";
import Notificaciones from "./Notificaciones.js";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    User,
    Notificaciones,
  },
});
// Cambios
