<template>
  <!-- <button @click="test()">TEST NOTI</button> -->
  <!--NUEVO NAVBAR -->
  <MDBNavbar
    expand="lg"
    container
    dark
    bg="dark"
    @focusout="cerrarSideBar()"
    tabindex="0"
  >
    <MDBNavbarBrand
      class="d-none d-sm-none d-md-block"
      @click="goHome()"
      style="font-weight: bold"
    >
      365 Equipo de trabajo
    </MDBNavbarBrand>
    <MDBNavbarToggler
      @click="estadoNavbar = !estadoNavbar"
      target="#navbarColor01"
    ></MDBNavbarToggler>
    <MDBCollapse id="navbarColor01" v-model="estadoNavbar">
      <MDBNavbarBrand
        class="d-block d-sm-block d-md-none"
        @click="goHome()"
        style="font-weight: bold"
      >
        365 Equipo de trabajo
      </MDBNavbarBrand>
      <MDBNavbarNav class="mb-2 mb-lg-0">
        <MDBNavbarItem
          to="#"
          @click="goHome()"
          :active="
            router.currentRoute.value.path == '/portal-empleado' ||
            router.currentRoute.value.path == '/portales' ||
            router.currentRoute.value.path == '/'
          "
        >
          <i class="fas fa-home"></i> Inicio
        </MDBNavbarItem>
        <MDBNavbarItem to="#" @click="actualizarApp()">
          <i class="fas fa-cloud-download-alt"></i> Actualizar App
        </MDBNavbarItem>
        <MDBNavbarItem v-if="logeado" to="#" @click="signOut()">
          <i class="fas fa-sign-out-alt"></i> Cerrar sesión
        </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBCollapse>

    <MDBNavbarNav class="mb-2 mb-lg-0 d-flex flex-row">
      <MDBNavbarItem
        to="#"
        v-if="logeado"
        class="me-3 me-lg-0"
        @click="enviarCodigo"
      >
        <img
          :src="getProfilePicSrc()"
          class="rounded-circle"
          height="25"
          width="25"
          alt=""
          loading="lazy"
        />
      </MDBNavbarItem>
    </MDBNavbarNav>
  </MDBNavbar>

  <!--FINAL NUEVO NAVBAR -->

  <MDBModal
    id="confirmarAccionModal"
    v-model="confirmarAccionModal"
    fullscreen
    tabindex="-1"
    labelledby="confirmarAccionModalLabel"
  >
    <MDBModalHeader>
      <MDBModalTitle id="confirmarAccionModalLabel">
        <i
          class="fas fa-arrow-circle-left pe-auto"
          @click="confirmarAccionModal = false"
        />
        Confirmar operación
      </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <div class="row justify-content-center">
        <div class="col-12 col-xl-6 col-md-6">
          <h4 class="text-center mt-3">
            Te hemos enviado un código al email
            {{ currentUser.email }}
          </h4>
          <p class="mt-5 text-center">Introducir código</p>
          <div class="input-group">
            <input
              id="inputConfirmar"
              type="text"
              class="form-control"
              v-model="codigoInput"
            />
          </div>
        </div>
      </div>
      <div class="text-center">
        <MDBBtn
          class="text-light mt-4 m-auto rounded-8 w-auto"
          color="success"
          @click="validarCodigo()"
        >
          Validar
        </MDBBtn>
      </div>
    </MDBModalBody>
  </MDBModal>
</template>

<script>
import { onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { logout, loginGoogleWithRedirect } from "./firebase/authentication";
import { getPermisssions } from "../components/rolesPermisos";
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBBtn,
} from "mdb-vue-ui-kit";
import { useRouter } from "vue-router";
import { axiosInstance } from "@/axios.js";
import { obtenerUrlImagen } from "./firebase/storage";
import Swal from "sweetalert2";
export default {
  name: "NavComponent",
  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,

    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBBtn,
  },

  setup() {
    const router = useRouter();
    const estadoNavbar = ref(false);
    const store = useStore();
    // const user = computed(() => store.state.User);
    const logeado = computed(() => store.state.User.logeado);
    const displayName = computed(() => store.state.User.displayName);
    const rutaActual = computed(() => router.currentRoute.value.path);
    const mostrarNoti = Math.floor(Math.random() * (99 - 0 + 1) + 0);
    const modalNotificaciones = ref(false);
    const collapse5 = ref(false);
    const dropdown8 = ref(false);
    const nNotificacionesPendientes = ref(0);
    const currentUser = computed(() => store.state.User);
    const confirmarAccionModal = ref(false);
    const arrayVerificacion = ref([]);
    const codigoInput = ref(null);
    const collapse7 = ref(false);
    const datos = ref([]);
    let file = null;
    const logeadoWatcher = computed(() => logeado.value);

    async function obtenerNotificacionesPendientes() {
      if (currentUser.value.logeado) {
        try {
          nNotificacionesPendientes.value =
            store.getters["Notificaciones/notificacionesPendientes"].length;
        } catch (error) {
          console.error(error);
        }
      }
    }

    function goHome() {
      if (getPermisssions("Panel_admin")) {
        router.push("/portales");
      } else {
        router.push("/portal-empleado");
      }
    }

    function cerrarSideBar() {
      estadoNavbar.value = false;
    }

    /* Login con Google */
    function login() {
      loginGoogleWithRedirect();
    }

    function signOut() {
      logout();
      nNotificacionesPendientes.value = 0;
      cerrarSideBar();
    }

    function test() {
      console.log("test");
    }

    function actualizarApp() {
      location.reload(true);
    }

    //Generar codigo MFA
    const generarCodigo = (num) => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result1 = " ";
      const charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
        result1 += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result1.trim();
    };

    //Guardar codigo en mongo
    const enviarCodigo = () => {
      Swal.fire({
        title: `¿Seguro que quieres ver tu perfil?`,
        text: "Escribe el código que te enviaremos por correo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Enviar código!",
      }).then((result) => {
        if (result.isConfirmed) {
          confirmarAccionModal.value = true;
          const datosMFA = {
            accion: "Ver Perfil",
            uid: currentUser.value.uid,
            codigo: generarCodigo(5),
            utilizado: false,
          };
          axiosInstance
            .post("verificacionmfa/nuevaVerificacionMFA", datosMFA)
            .then((res) => {
              if (!res) {
                Swal.fire("Oops...", res, "error");
              }
            })
            .catch((err) => {
              console.log(err);
              Swal.fire("Oops...", err.message, "error");
            });
        }
      });
    };

    async function validarCodigo() {
      try {
        const res = await axiosInstance.get(
          "verificacionmfa/getVerificacionMFA",
          {
            params: {
              uid: currentUser.value.uid,
              utilizado: false,
            },
          }
        );
        if (res.data.ok) {
          arrayVerificacion.value = res.data.data;
          arrayVerificacion.value.forEach((element) => {
            element.codigo;
            if (element.codigo == codigoInput.value) {
              Swal.fire("Perfecto...", "Acceso permitido", "success").then(
                async () => {
                  (confirmarAccionModal.value = false), router.push("/perfil");
                  await axiosInstance
                    .post("/verificacionmfa/deleteVerificacionMFA", {
                      _id: element._id,
                    })
                    .then((result) => {
                      console.log(result.data);
                    });
                }
              );
            } else {
              Swal.fire("Oops...", "Codigo incorrecto", "error");
            }
          });
        }
        codigoInput.value = null;
      } catch (error) {
        console.log(error);
      }
    }

    function loadProfilePic() {
      if (datos.value.displayFoto && datos.value.displayFoto.length > 0) {
        obtenerUrlImagen(datos.value.displayFoto)
          .then((url) => {
            datos.value.displayFoto = url;
          })
          .catch((error) => {
            console.error("Error al cargar la imagen:", error);
          });
      }
    }
    function getProfilePicSrc() {
      if (logeado.value && datos.value.displayFoto) {
        return datos.value.displayFoto;
      } else if (file) {
        return URL.createObjectURL(file);
      } else {
        return "/avatarIcon.png";
      }
    }

    async function getAllIdPerfil() {
      try {
        datos.value = currentUser.value;
        mostrarFoto();
      } catch (error) {
        console.log(error);
      }
    }

    //Mostrar foto
    async function mostrarFoto() {
      if (datos.value.displayFoto && datos.value.displayFoto.length > 0) {
        const url = await obtenerUrlImagen(datos.value.displayFoto);
        datos.value.displayFoto = url;
      }
    }

    watch(
      () => datos.value.displayFoto,
      () => {
        loadProfilePic();
      }
    );

    onMounted(() => {
      getAllIdPerfil();
    });

    // Watcher para ejecutar las funciones cuando se inicie sesión
    watch(logeadoWatcher, (newValue) => {
      if (newValue) {
        getAllIdPerfil();
      }
    });

    return {
      rutaActual,
      logeado,
      displayName,
      collapse5,
      dropdown8,
      mostrarNoti,
      modalNotificaciones,
      estadoNavbar,
      router,
      nNotificacionesPendientes,
      confirmarAccionModal,
      currentUser,
      arrayVerificacion,
      codigoInput,
      collapse7,
      logeadoWatcher,

      signOut,
      login,
      cerrarSideBar,
      goHome,
      test,
      actualizarApp,
      obtenerNotificacionesPendientes,
      enviarCodigo,
      validarCodigo,
      getProfilePicSrc,
      getPermisssions,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar-nav {
  margin-right: unset !important;
}
</style>
