export default {
  namespaced: true,
  state: {
    logeado: false,
    uid: "",
    displayName: "",
    email: "",
    customClaims: null,
    idSql: null,
    idTienda: null,
    nombreTienda: null,
    nombre: "",
    llevaEquipo: false,
    token: null,
    displayFoto: null,
    dni: null,
    roles: [],
    permissions: [],
  },
  mutations: {
    setInfoUsuarioMutation(state, user) {
      state.logeado = user.logeado;
      state.uid = user.uid;
      (state.displayName = user.displayName), (state.email = user.email);
      state.customClaims = user.customClaims;
      state.token = user.token;
    },
    setRolesMutation(state, payload) {
      state.roles = payload;
    },
    setPermissionsMutation(state, payload) {
      state.permissions = payload;
    },
    setIdSqlMutation(state, user) {
      state.idSql = user.idSql;
      state.idTienda = user.idTienda;
      state.nombreTienda = user.nombreTienda;
      state.llevaEquipo = user.llevaEquipo;
      state.displayFoto = user.displayFoto;
      state.dni = user.dni;
      state.telefono = user.telefono;
      state.roles = user.roles;
      state.permissions = user.permissions;
    },
    setTokenMutation(state, token) {
      state.token = token;
    },
    setNombreMutation(state, nombre) {
      state.nombre = nombre;
      state.displayName = nombre;
    },
    clearUserMutation(state) {
      state.logeado = false;
      state.uid = null;
      state.displayName = null;
      state.email = null;
      state.customClaims = null;
      state.idSql = null;
      state.idTienda = null;
      state.nombreTienda = null;
      state.nombre = null;
      state.llevaEquipo = false;
      state.token = null;
      state.displayFoto = null;
      state.dni = null;
      state.roles = [];
      state.permissions = [];
    },
  },
  getters: {
    logeado: (state) => state.logeado,
    roles: (state) => state.roles,
    permissions: (state) => state.permissions,
    uid: (state) => state.uid,
    displayName: (state) => state.displayName,
    email: (state) => state.email,
    customClaims: (state) => state.customClaims,
    idSql: (state) => state.idSql,
    idTienda: (state) => state.idTienda,
    nombreTienda: (state) => state.nombreTienda,
    token: (state) => state.token,
    user: (state) => {
      return {
        uid: state.uid,
        displayName: state.displayName,
        email: state.email,
        customClaims: state.customClaims,
        idSql: state.idSql,
        idTienda: state.idTienda,
        nombreTienda: state.nombreTienda,
        llevaEquipo: state.llevaEquipo,
        displayFoto: state.displayFoto,
      };
    },
  },
  actions: {
    setInfoUsuario({ commit }, user) {
      commit("setInfoUsuarioMutation", user);
    },
    setIdSql({ commit }, user) {
      commit("setIdSqlMutation", user);
    },
    setToken({ commit }, token) {
      commit("setTokenMutation", token);
    },
    setNombre({ commit }, nombre) {
      commit("setNombreMutation", nombre);
    },
    clearUser({ commit }) {
      commit("clearUserMutation");
    },
    setRoles({ commit }, roles) {
      commit("setRolesMutation", roles);
    },
    setPermissions({ commit }, permissions) {
      commit("setPermissions", permissions);
    },
  },
};
