<template>
  <MDBModal
    id="staticBackdrop"
    tabindex="-1"
    labelledby="staticBackdropLabel"
    v-model="modalNotifications"
    staticBackdrop
  >
    <MDBModalHeader>
      <MDBModalTitle>Notificaciones sin leer</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <!-- <MDBListGroup light>
        <MDBListGroupItem
          v-for="(item, index) in arrayNotifications"
          v-bind:key="index"
          class="rounded-3 mb-2"
          tag="a"
          href="#"
          action
          noBorder
          spacing
          :color="index % 2 === 0 ? 'primary' : 'secondary'"
        >
          {{ item.titulo }}
        </MDBListGroupItem>
      </MDBListGroup> -->
      <MDBListGroup light style="min-width: 22rem">
        <MDBListGroupItem
          v-for="(item, index) in arrayNotifications"
          v-bind:key="index"
          class="d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <img
              :src="getImage(item.creador)"
              alt=""
              style="width: 45px; height: 45px"
              class="rounded-circle"
            />
            <div class="ms-3">
              <p class="fw-bold mb-1">{{ item.titulo }}</p>
              <p class="text-muted mb-0">
                {{ item.mensaje }}
              </p>
            </div>
          </div>
          <!-- <a
            class="btn bg-success text-light fw-bold btn-link btn-rounded btn-sm"
            href="#"
            @click="marcarComoLeida(item._id)"
            role="button"
            >OK</a
          > -->
          <a
            class="btn bg-success text-light fw-bold btn-link btn-rounded btn-sm"
            href="#"
            @click="redirectToUrl(item)"
            role="button"
            >ver</a
          >
        </MDBListGroupItem>
      </MDBListGroup>
    </MDBModalBody>
    <!-- <MDBModalFooter>
      <MDBBtn color="warning" @click="modalNotifications = false">
        Ignorar
      </MDBBtn>
    </MDBModalFooter> -->
  </MDBModal>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  // MDBModalFooter,
  // MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-vue-ui-kit";
import { axiosInstance } from "@/axios";
import Swal from "sweetalert2";
// import router from "../router/index";
// import { getEquipoDe } from "./equipoGeneral";
import { useStore } from "vuex";

export default {
  name: "NotificationsComponent",
  components: {
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    // MDBModalFooter,
    // MDBBtn,
    MDBListGroup,
    MDBListGroupItem,
  },
  setup() {
    const store = useStore();
    const modalNotifications = ref();
    const arrayNotifications = ref([]);
    const uid = store.getters["User/uid"];
    const user = computed(() => store.state.User);

    function getImage(creador) {
      switch (creador) {
        case "SISTEMA":
          return "/favicon.png";
        case "RRHH":
          return "/rrhh.png";
      }
    }

    async function marcarComoLeida(id) {
      try {
        const resLeida = await axiosInstance.post(
          "notificaciones/marcarComoLeida",
          { id }
        );

        if (!resLeida.data.ok) throw Error(resLeida.data.message);
        getNotificaciones();
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      } catch (err) {
        console.log(err);
        Swal.fire("Oops...", err.message, "error");
      }
    }

    function redirectToUrl(url) {
      console.log(url);
      modalNotifications.value = false;
      //Marcar como leida cuando le de al boton ver
      marcarComoLeida(url._id);
      window.location.href = url.url; // ir a la url pero recargando la pàgina
      modalNotifications.value = false;
    }

    function getNotificaciones() {
      if (user.value.logeado) {
        const notificaciones =
          store.getters["Notificaciones/notificacionesPendientes"];

        if (notificaciones.length > 0) {
          arrayNotifications.value = notificaciones;
          modalNotifications.value = true;
        } else {
          arrayNotifications.value = [];
          modalNotifications.value = false;
        }
      }
    }

    onMounted(async () => {
      getNotificaciones();
    });

    return {
      modalNotifications,
      arrayNotifications,
      uid,
      getImage,
      marcarComoLeida,
      getNotificaciones,
      redirectToUrl,
    };
  },
};
</script>

<style lang="scss" scoped></style>
